:root {
    --purple-1: #220F2D;
    --purple-2: #371A46;
    --red-1: #F91C1C;
    --left-1: #D32E31;
    --middle-1: #BD007E;
    --right-1: #731BBE;
    --left-2: #FA7B6E;
    --right-2: #9942EE;
    --light-grey: #F5F5F5;
    --automaton-color: #F16053;
    --den-1: #921C6A;
    --den-2: #CB1C52;
    --den-3: #B91C6A;
    --den-3-alt: #911C6A;
    --den-4: #9A1B8E;
    --den-5: #731BBE;
    --primary-button: var(--den-3);
    --primary-button-alt: var(--den-3-alt);
}

.lineargradient {
    background: linear-gradient(30deg, var(--left-1), var(--right-1));
    color: transparent !important;
    background-clip: text !important;
    -webkit-background-clip: text;
}

.lineargradient2, .lineargradient::selection, .lineargradient::-webkit-selection {
    background: linear-gradient(30deg, var(--left-2), var(--right-2));
    color: transparent !important;
    background-clip: text !important;
    -webkit-background-clip: text;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

.arrowBounce {
    animation: bounce 2s infinite;
    position: fixed;
    left: 50%;
    bottom: 60px;
    opacity: 0.3;
}

.vLine {
    z-index: 1;
}

.vLine::before{
    border-right: 3px solid var(--den-3);
    height: 50%;
    min-height: 100px;
    top: 25%;
}

.segmentWithBorder {
    z-index: 1;
    border-bottom: 0;
}

.segmentWithBorder::after {
    height: 3px;
    content: '';
    position: absolute;
    left: 37.5%;
    bottom: 0;
    width: 25%;
    border-bottom: 2px solid var(--den-3);
}

.greyTopToBottom {
    background: linear-gradient(180deg, var(--light-grey), white);
    z-index: 1;
    border-bottom: 0;
}

.greyTopToBottom::after {
    height: 3px;
    content: '';
    position: absolute;
    left: 40%;
    bottom: 0;
    width: 20%;
    border-bottom: 2px solid var(--den-3);
}

.standardP {
    font-size: 1.33em;
    justify-content: center;
    align-items: center;
    text-justify: inter-word;
    text-align: justify;
}

.centerFlex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}