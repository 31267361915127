/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 20, 2024 */



@font-face {
    font-family: 'jetbrains_monoregular';
    src: url('jetbrainsmono-variablefont_wght-webfont.woff2') format('woff2'),
         url('jetbrainsmono-variablefont_wght-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}