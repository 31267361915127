.element {
    position: relative;
    z-index: 1;
    border: double 4px transparent;
    border-radius: 5px;
}

.element::before {
    content: "";
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    background: linear-gradient(90deg, red, blue);
    z-index: -1;
    border-radius: 5px;
}

.custom-link {
    color: var(--den-3);
}
.custom-link:hover {
    color: var(--den-3-alt);
}
