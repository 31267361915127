.rightSkill {

}

.accordionDivider {
    width: 100%;
    max-width: 1155px;
    margin-left: auto !important;
    margin-right: auto !important;
}

.headerRow{
    font-size: 2em;
}

.contentColumn {
    padding-bottom: 5rem;
    flex-direction: column;
}

.interimParagraph {
    font-size: 1.33em;
    text-align: justify;
    text-justify: inter-word;
}