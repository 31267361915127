.ui.inverted.custom.button {
    background-color: transparent; /* Your custom color */
    box-shadow: 0 0 0 2px var(--primary-button) inset; /* Your custom color */
    color: var(--primary-button); /* Your custom text color */
}

.ui.inverted.custom.button:hover,
.ui.inverted.custom.button:focus {
    background-color: var(--primary-button);
    box-shadow: none ;
    color: #ffffff;
}

.ui.inverted.custom.button:active {
    background-color: var(--primary-button);
    color: #ffffff;
}


.ui.inverted.purple2.button {
    background-color: transparent !important; /* Your custom color */
    box-shadow: 0 0 0 2px var(--purple-2) inset !important; /* Your custom color */
    color: var(--purple-2); /* Your custom text color */
}

.ui.inverted.purple2.button:hover,
.ui.inverted.purple2.button:focus {
    background-color: var(--purple-2) !important;
    box-shadow: none !important;
    color: #ffffff !important;
}

.ui.inverted.custom.button:active {
    background-color: var(--purple-2);
    color: #ffffff;
}

.ui.custom.button {
    background-color: var(--primary-button);
    color: #ffffff;
}

.ui.custom.button:hover,
.ui.custom.button:focus {
    background-color: var(--primary-button-alt);
    color: #ffffff;
    text-shadow: none;
}

.ui.gradient.button {
    background: linear-gradient(30deg, var(--red-1), var(--right-1)) ;
    color: white ;
}

.ui.gradient.button:hover {
    background: linear-gradient(120deg, var(--red-1, red), var(--right-1, purple));
    color: white;
}

